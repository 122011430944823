<template>
  <v-img src="@/assets/biometric.jpg">
    <v-container>
      <v-row class="text-center pt-6">
        <v-col class="mb-4 mt-16">
          <h1
            class="display-2 white--text font-weight-bold mb-3 hidden-sm-and-down"
          >
            Face and Fingerprint Biometrics
          </h1>
          <h3 class="white--text">"Light, Simple, Fast and Easy"</h3>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-card color="" class="pa-5">
          <v-card-title class="justify-center">
            Administrator Login
          </v-card-title>
          <v-card-text>
            <v-text-field
              prepend-icon="mdi-account"
              label="username"
              v-model="form.username"
              autofocus
            ></v-text-field>
            <v-text-field
              prepend-icon="mdi-lock"
              label="Password"
              type="password"
              v-model="form.password"
            ></v-text-field>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn color="primary" block @click="login()">
              <span class="font-weight-bold">login</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-row>
      <p class="text-center mt-10 overline white--text">
        Developed by: JMBComputers | Jan Michael Besinga 2023
        <br />
        ZKTECO | NGTECO BIOMETRICS
      </p>
      <p class="text-center caption white--text">
        Version {{ packageJson.version }}
      </p>

      <progress-circular :progress="progress_circular" />
    </v-container>
  </v-img>
</template>

<script>
import ProgressCircular from "@/components/ProgressCircular.vue";
import { mapActions, mapMutations } from "vuex";
import packageJson from "../../package.json";

export default {
  name: "SignIn",
  components: {
    ProgressCircular,
  },
  data() {
    return {
      packageJson: packageJson,
      progress_circular: false,
      form: {
        username: "",
        password: "",
      },
    };
  },
  computed: {
    imgSize() {
      let x = 0;
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          x = 150;
          break;
        case "sm":
          x = 180;
          break;
        case "md":
          x = 200;
          break;
        case "lg":
          x = 230;
          break;
        case "xl":
          x = 250;
          break;
      }
      return x;
    },
  },
  methods: {
    ...mapActions({
      signin: "auth/signin",
      set_notification: "notification/set_notification",
    }),
    ...mapMutations({
      set_progress: "auth/SET_PROGRESS_CIRCULAR",
    }),
    async login() {
      this.set_progress(true);
      try {
        await this.signin(this.form);
        this.$router.push("/attendances");
        this.set_notification({
          message: "Successfully logged in!",
          color: "success",
          showing: true,
          icon: "mdi-check",
        });
        this.set_progress(false);
      } catch (error) {
        this.set_progress(false);

        if (error.response.status == 401) {
          alert("Account not found. Please contact the administrator");
        } else {
          alert("Server error: Please contact the administrator");
        }
      }
    },
  },
};
</script>
