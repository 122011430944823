<template>
  <div class="d-flex">
    <p class="mr-2">Import Attendance</p>
    <input type="file" ref="fileInput" accept=".dat" />
    <v-btn small color="primary " :loading="loading" @click="uploadFile"
      >Upload File</v-btn
    >
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    uploadFile() {
      this.loading = true;
      const formData = new FormData();
      formData.append("file", this.$refs.fileInput.files[0]);
      axios
        .post("/import_attendances", formData)
        .then((res) => {
          console.log(res.data);
          alert("Successfully imported attendance from file");
          this.loading = false;
        })
        .catch((e) => {
          alert(e.message);
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
