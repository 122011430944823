<template>
  <v-navigation-drawer
    v-if="authenticated"
    class=""
    app
    src="@/assets/biometric.jpg"
    dark
  >
    <v-list dense>
      <v-list-item>
        <v-list-item-avatar>
          <img src="@/assets/jmb_logo.png" />
        </v-list-item-avatar>
        <v-list-item-content>
          <!-- <template v-if="authenticated"> -->
          <v-list-item-title class="red--text accent-3 overline"
            >JMB<span class="blue--text">COMPUTERS</span></v-list-item-title
          >
          <v-list-item-subtitle class="white--text text--darken-1"
            >Fast and Reliable Services</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
      <v-list-item
        dense
        class="subtitle-2"
        v-for="item in menuItems"
        :key="item.title"
        :to="item.path"
      >
        <v-list-item-action>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>{{ item.title }}</v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      menuItems: [
        {
          title: "Attendance Logs",
          path: "/attendances",
          icon: "mdi-book",
          // color: "green",
        },
        {
          title: "Employees",
          path: "/employees",
          icon: "mdi-account-multiple",
          // color: "green",
        },

        {
          title: "Departments",
          path: "/departments",
          icon: "mdi-domain",
          // color: "green",
        },
        {
          title: "Account Information",
          path: "/device_info",
          icon: "mdi-fingerprint",
          // color: "green",
        },
        {
          title: "Admin Controls",
          path: "/admin_controls",
          icon: "mdi-key",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
    }),
  },
};
</script>

<style></style>
