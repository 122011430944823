<template>
  <v-container class="pa-10">
    <div class="d-flex">
      <h2>User Information</h2>
      <v-spacer></v-spacer>
      <add-user-modal />
    </div>
    <v-divider class="mt-2 mb-2"></v-divider>
    <p class="overline">Registered Users</p>
    <v-data-table :items="users" :headers="user_headers">
      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex">
          <v-btn icon color="yellow darken-2" @click="show_edit_modal(item)"
            ><v-icon>mdi-pencil</v-icon></v-btn
          >
          <v-btn icon color="red" class="ml-2"
            ><v-icon>mdi-delete</v-icon></v-btn
          >
        </div>
      </template>
    </v-data-table>
    <!-- lazy loaded components -->
    <edit-user-modal
      v-if="show_edit"
      :item="selected_item"
      @close_edit="show_edit = false"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import addUserModal from "../components/User/addUserModal.vue";
export default {
  components: {
    addUserModal,
    EditUserModal: () => import("../components/User/editUserModal.vue"),
  },
  created() {
    if (this.users.length <= 0) {
      this.get_users();
    }
  },
  data() {
    return {
      selected_item: [],
      show_edit: false,
      user_headers: [
        { text: "Actions", value: "actions" },
        {
          text: "User ID",
          value: "id",
        },
        { text: "User Name", value: "username" },
        { text: "Access Level", value: "access_level" },
        { text: "Device Limit", value: "device_limit" },

        {
          text: "Created at",
          value: "created_at",
        },
        {
          text: "Updated at",
          value: "updated_at",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      get_users: "user/get_users",
    }),
    show_edit_modal(item) {
      this.selected_item = item;
      this.show_edit = true;
    },
  },
  computed: {
    ...mapGetters({
      users: "user/users",
    }),
  },
};
</script>

<style></style>
