import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import alert from "./components/Alert.vue";

Vue.config.productionTip = false;
// axios.defaults.baseURL = "http://127.0.0.1:8000/api";
axios.defaults.baseURL = "https://biometric-server.jmbcomputers.com/api";
require("@/store/subscriber");
store.dispatch("auth/attempt", localStorage.getItem("token")).then(() => {
  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
    created() {
      Vue.component("alert", alert);
    },
  }).$mount("#app");
});
