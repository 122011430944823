<template>
  <div>
    <v-btn color="primary" small @click="dialog = true">Add User</v-btn>
    <v-dialog v-model="dialog" width="350" class="ma-auto">
      <v-card>
        <v-card-title>Add User</v-card-title>
        <v-card-text>
          <v-text-field
            label="User Name"
            v-model="form.username"
            prepend-icon="mdi-account"
          ></v-text-field>
          <v-select
            label="Access Level"
            v-model="form.access_level"
            :items="[1, 2]"
            prepend-icon="mdi-key"
          ></v-select>
          <v-select
            label="Device Limit"
            prepend-icon="mdi-numeric"
            v-model="form.device_limit"
            :items="[1, 2, 3, 4, 5]"
          ></v-select>
          <v-text-field
            prepend-icon="mdi-key"
            type="password"
            label="Password"
            v-model="form.password"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="submit_user" :loading="button_loading">
            Add
          </v-btn>
          <v-btn @click="dialog = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      error_message: null,
      button_loading: false,
      show_alert: false,
      alert_message: "",
      alert_type: "",
      dialog: false,
      form: {
        username: null,
        password: null,
      },
    };
  },
  methods: {
    check_password() {
      if (this.form.passwod !== this.form.confirm_password) {
        this.error_message = "Password do not match";
      } else {
        this.error_message = null;
      }
    },
    ...mapActions({
      add_user: "user/add_user",
    }),
    async submit_user() {
      if (!this.form.username || !this.form.password) {
        this.show_alert_bar("Please check all fields!", "error");
        return;
      } else {
        this.button_loading = true;
        await this.add_user(this.form)
          .then(() => {
            alert("Successfully added a new user");
          })
          .catch((e) => {
            alert(e);
          });

        this.button_loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
