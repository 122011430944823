<template>
  <v-container>
    <div class="pa-10">
      <div class="d-flex">
        <h2>Departments</h2>
        <v-spacer></v-spacer>
        <add-department-modal />
      </div>

      <v-divider class="mt-2 mb-2"></v-divider>
      <div>
        <p class="overline">Registered Departments</p>
      </div>
      <v-data-table :items="departments" :headers="headers" v-if="departments">
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex">
            <edit-department-modal :item="item" />
            <v-btn icon small color="error" @click="del(item)"
              ><v-icon>mdi-delete</v-icon></v-btn
            >
          </div>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import AddDepartmentModal from "../components/Department/addDepartmentModal.vue";
import EditDepartmentModal from "../components/Department/editDepartmentModal.vue";
export default {
  components: {
    AddDepartmentModal,
    EditDepartmentModal,
  },
  data() {
    return {
      department_modal: false,
      table_loader: false,
      search: "",
      headers: [
        { text: "Action", value: "actions", filterable: false },
        {
          text: "Department Name",
          value: "department_name",
        },
        { text: "Signatory", value: "signatory" },
        { text: "Signatory Position", value: "signatory_position" },
        { text: "Morning Time Out", value: "morning_out" },
        {
          text: "Number of Registered Accounts",
          value: "employees_count",
        },
        {
          text: "Created at",
          value: "created_at",
        },
        { text: "Updated at", value: "updated_at" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      departments: "department/departments",
      user: "auth/user",
    }),
  },
  methods: {
    del(item) {
      let x = window.confirm("Are you sure you want to delete this item?");
      if (x) {
        this.delete_department(item.id).then(() => {
          alert("Successfully deleted an item");
        });
      }
    },
    ...mapActions({
      get_departments: "department/get_departments",
      delete_department: "department/delete_department",
    }),
  },
  async created() {
    this.table_loader = true;
    // console.log(this.departments, "asdsa");
    if (this.departments.length <= 0) await this.get_departments(this.user.id);
    // if(this.departments.le

    this.table_loader = false;
  },
};
</script>

<style lang="scss" scoped></style>
