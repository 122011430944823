<template>
  <v-container>
    <div class="pa-10">
      <!-- <div class="d-flex justify-left mb-2">
        <v-spacer></v-spacer>
      
      </div> -->

      <h2 class="display-3">Attendance Logs</h2>
      <v-divider class="mt-3 mb-3"></v-divider>
      <div class="d-flex">
        <import-attendance />
        <v-spacer></v-spacer>
        <generate-attendance-modal />
      </div>
      <v-text-field label="Search" v-model="attendance_search"></v-text-field>
      <!-- Custom Search -->

      <v-data-table
        :search="attendance_search"
        :items="all_attendances"
        :headers="attendance_headers"
      >
        <template v-slot:[`item.type`]="{ item }">
          <v-chip :color="item.type == 1 ? 'error' : 'primary'">
            {{ item.type == 1 ? "Out" : "In" }}</v-chip
          >
        </template>
      </v-data-table>
      <v-row class="mt-4">
        <v-col
          ><ul>
            <span class="font-weight-bold success--text"> Things to note:</span>

            <li>
              Your attendances is stored in the cloud within your account.
              Incase the biometric device fails, you will still get your list of
              attendances
            </li>
            <li>
              Only the administrator can alter the date of each attendance
              entry.
            </li>
            <li>
              Incase of technical issues, report/contact the system developer
              janmichaelbesinga873@gmail.com (Mr. Jan Michael Besinga).
            </li>
            <li>
              Changes and updates to the system will be applied on all users.
              Hence, you will experience the latest and updated features 24/7.
            </li>
            <li>Support by spreading the good news.</li>
          </ul></v-col
        >
        <v-col
          ><h2>For first time users:</h2>
          <ol>
            <li>
              Make sure the device is registered. Go to Account Information
              Section.
            </li>
            <li>
              Before importing attendance, make sure you've already registered
              each employees and departments. Go to Employees, and Departments
              section.
            </li>
            <li>
              You can only view the attendance when it is imported into the
              system.
            </li>
            <li>
              You need to generate attendance first before you can see the list
              of attendances.
            </li>
          </ol>
        </v-col>
      </v-row>

      <v-row class="d-flex justify-center mt-10">
        <v-col cols="4">
          <v-img src="@/assets/device_logo.png"></v-img>
          <p class="text-center caption mt-4">
            Maintained and developed by: Jan Michael Besinga | 2024
            <br />
            <span class="caption">Version {{ packageJson.version }}</span>
          </p>
        </v-col>
      </v-row>
    </div>

    <alert :text="alert_message" :type="alert_type" v-if="show_alert"></alert>
  </v-container>
</template>
<script>
import packageJson from "../../package.json";
import { mapActions, mapGetters } from "vuex";
import ImportAttendance from "../components/Attendance/importAttendance.vue";
import GenerateAttendanceModal from "../components/Attendance/generateAttendanceModal.vue";
export default {
  components: {
    attendanceTable: () =>
      import("@/components/Attendance/attendanceTable.vue"),
    ImportAttendance,
    GenerateAttendanceModal,
  },
  data() {
    return {
      attendance_search: "",
      show_alert: false,
      alert_message: "",
      alert_type: "",
      packageJson: packageJson,
      days: [],
      packageJson: packageJson,

      generate_loading_button: false,
      custom_search_form: {
        name: "",
      },
      form: {
        year: "",
        month: "",
        department_id: "",
        date_to: "",
        date_from: "",
        employment_status: "",
        device_id: "",
      },

      attendance_headers: [
        { text: "ID", value: "userid" },
        { text: "Name", value: "employee.name" },
        { text: "Time Stamp", value: "timestamp" },
        { text: "Type", value: "type" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",

      attendances: "attendance/attendances",
      employees: "employee/employees",
      attendance_request_info: "attendance/attendance_request_info",
      all_attendances: "attendance/all_attendances",
    }),
  },
  async created() {
    this.table_loader = true;
    if (this.employees.length <= 0) await this.get_employees();

    if (this.all_attendances.length <= 0) await this.get_all_attendances();
    await this.get_devices();
    this.table_loader = false;

    var x;
    for (x = 1; x <= 31; x++) {
      this.days.push(x);
    }
  },
  methods: {
    ...mapActions({
      get_all_attendances: "attendance/get_all_attendances",
      generate_attendance: "attendance/generate_attendance",
      get_departments: "department/get_departments",
      get_employees: "employee/get_employees",
      get_devices: "device/get_devices",
    }),

    async submit() {
      this.generate_loading_button = true;
      if (!this.form.department_id || !this.form.month) {
        this.show_alert_bar("Please check all fields!", "error");
        this.generate_loading_button = false;
        return;
      }
      await this.generate_attendance(this.form);
      this.generate_loading_button = false;
      this.show_alert_bar("Sucessfully Generated!", "success");
    },

    show_alert_bar(message, type) {
      this.show_alert = true;
      this.alert_message = message;
      this.alert_type = type;
      setTimeout(() => {
        this.show_alert = false;
      }, 6000);
    },
  },
};
</script>
<style></style>
