import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth.js";
import notification from "./notification.js";
import attendance from "./attendance.js";
import employee from "./employee.js";
import department from "./department.js";
import schedule from "./schedule.js";
import device from "./device.js";
import user from "./user.js";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    notification,
    attendance,
    employee,
    schedule,
    department,
    device,
    user,
  },
});
