import axios from "axios";
export default {
  namespaced: true,
  state: {
    // state of app. can store anything from strings to objects
    users: [],
  },
  // read info from state
  getters: {
    users(state) {
      return state.users;
    },
  },
  mutations: {
    // update the state
    SET_USERS(state, users) {
      state.users = users;
    },
    EDIT_USER(state, user) {
      let index = null;
      state.users.forEach(function (element, i) {
        if (element.id == user.id) index = i;
      });
      state.users.splice(index, 1, user);
    },
    ADD_USER(state, user) {
      state.users.unshift(user);
    },
  },
  actions: {
    async get_users({ commit }) {
      let response = await axios.get("/users");
      commit("SET_USERS", response.data);
    },
    async add_user({ commit }, request) {
      let response = await axios.post("/user", request);
      commit("ADD_USER", response.data);
    },
    async edit_user({ commit }, request) {
      let response = await axios.put("/user", request);
      commit("EDIT_USER", response.data);
    },
  },
};
