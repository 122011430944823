import axios from "axios";
export default {
  namespaced: true,
  state: {
    schedules: null,
    schedule: null,
  },
  getters: {
    schedules(state) {
      return state.schedules;
    },
  },
  mutations: {
    SET_SCHEDULES(state, schedules) {
      state.schedules = schedules;
    },
    ADD_SCHEDULE(state, schedule) {
      state.schedules.unschedule(schedule);
    },
    EDIT_SCHEDULE(state, schedule) {
      let index = null;
      state.schedules.forEach(function (element, i) {
        if (element.id == schedule.id) index = i;
      });
      state.schedules.splice(index, 1, schedule);
    },
  },
  actions: {
    async get_schedules({ commit }) {
      let response = await axios.get("/schedules");
      console.log(response.data);
      commit("SET_SCHEDULES", response.data);
    },
    async add_schedule({ commit }, request) {
      let response = await axios.post("/schedule", request);
      commit("ADD_SCHEDULE", response.data);
    },
    async edit_schedule({ commit }, request) {
      let response = await axios.put("/schedule", request);
      commit("EDIT_SCHEDULE", response.data);
    },
  },
};
