import axios from "axios";
export default {
  namespaced: true,
  state: {
    // state of app. can store anything from strings to objects
    attendances: [],
    all_attendances: [],
    attendance_request_info: null,
  },
  // read info from state
  getters: {
    all_attendances(state) {
      return state.all_attendances;
    },
    attendances(state) {
      return state.attendances;
    },
    attendance_request_info(state) {
      return state.attendance_request_info;
    },
  },
  mutations: {
    // update the state
    SET_ALL_ATTENDANCES(state, all_attendances) {
      state.all_attendances = all_attendances;
    },
    SET_ATTENDANCES(state, attendances) {
      state.attendances = attendances;
    },
    SET_ATTENDANCE_REQUEST_INFO(state, attendance_request_info) {
      state.attendance_request_info = attendance_request_info;
    },
  },
  actions: {
    async get_all_attendances({ commit }) {
      let response = await axios.get("/all_attendances");
      commit("SET_ALL_ATTENDANCES", response.data);
    },
    async generate_attendance({ commit }, request) {
      let response = await axios.post("/generate_attendance", request);
      console.log(response.data);
      commit("SET_ATTENDANCES", response.data);
      commit("SET_ATTENDANCE_REQUEST_INFO", request);
    },
    async download_attendances({}, device_id) {
      await axios.get("/import_attendances/" + device_id);
    },
    async insert_am_or_pm_to_attendance_table({}) {
      await axios.get("insert_am_or_pm_to_attendance_table");
    },
  },
};
