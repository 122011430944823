import axios from "axios";
export default {
  namespaced: true,
  state: {
    // state of app. can store anything from strings to objects
    token: null,
    user: null,
    // default_url: "http://127.0.0.1:8000",
    default_url: "https://biometric-server.jmbcomputers.com",
    progress_circular: false,
    device_connected: false,
  },
  // read info from state
  getters: {
    authenticated(state) {
      return state.token && state.user;
    },
    user(state) {
      return state.user;
    },
    token(state) {
      return state.token;
    },
    default_url(state) {
      return state.default_url;
    },
    progress_circular(state) {
      return state.progress_circular;
    },
    device_connected(state) {
      return state.device_connected;
    },
  },
  mutations: {
    // update the state
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_USER(state, data) {
      state.user = data;
    },
    SET_PROGRESS_CIRCULAR(state, bool) {
      state.progress_circular = bool;
    },
    CONNECT_DEVICE(state, bool) {
      state.device_connected = bool;
    },
    DISCONNECT_DEVICE(state, bool) {
      if (bool == 1) state.device_connected = 0;
    },
  },
  actions: {
    // make request to api but commit to mutations
    async signin({ dispatch }, credentials) {
      let response = await axios.post("login", credentials);
      return dispatch("attempt", response.data);
    },
    // attempt to access me api to check if token is valid
    // and give us back the users info
    async attempt({ commit, state }, token) {
      // if there is a token, commit
      if (token) {
        commit("SET_TOKEN", token);
        localStorage.setItem("token", token);
      }
      // if there is no token in the sate, stop the execution
      if (!state.token) {
        return;
      }
      // check token if it is valid in me api
      try {
        // pass header for authorization
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        let response = await axios.get("auth/attempt");
        // if it is true, set_user to response
        commit("SET_USER", response.data);
        // get services
      } catch (e) {
        // if errors occurs set state to null
        commit("SET_TOKEN", null);
        commit("SET_USER", null);
      }
    },
    signout({ commit }) {
      return axios.post("logout").then(() => {
        commit("SET_TOKEN", null);
        commit("SET_USER", null);
      });
    },
    async connect_device({ commit }) {
      let response = await axios.get("/connect");
      console.log(response.data);
      commit("CONNECT_DEVICE", response.data);
    },
    async disconnect_device({ commit }) {
      let response = await axios.get("/disconnect");
      console.log(response.data);
      commit("DISCONNECT_DEVICE", response.data);
    },
  },
};
