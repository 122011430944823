<template>
  <v-container>
    <div class="pa-10">
      <div class="d-flex">
        <h2>Employees</h2>
        <v-spacer></v-spacer>
        <add-employee-modal />
      </div>

      <v-text-field label="Search" v-model="search"></v-text-field>

      <v-data-table
        :search="search"
        v-model="schedule_form.employees"
        v-if="employees"
        :items="employees"
        :headers="headers"
        :loading="table_loader"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex">
            <attendance-modal :item="item" />
            <v-btn small color="yellow darken-1" @click="edit(item)" icon
              ><v-icon>mdi-pencil</v-icon></v-btn
            >
            <v-btn
              small
              color="red"
              icon
              @click="del(item)"
              class="ml-2 white--text"
              ><v-icon>mdi-delete</v-icon></v-btn
            >
          </div>
        </template>
      </v-data-table>

      <v-dialog v-model="schedule_dialog" max-width="600">
        <v-card>
          <v-card-title class="deep-purple darken-4 white--text"
            >Add/Edit Employee Schedules</v-card-title
          >
          <br />
          <span class="text-h5 font-weight-bold ml-6">Select Schedule</span>
          <v-card-text class="pt-6" v-if="schedules"> </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="schedule_dialog = !schedule_dialog"
              rounded
              color="deep-purple lighten-4 white--text"
              dark
              >cancel</v-btn
            >
            <v-btn
              @click="confirm_submit"
              rounded
              color="deep-purple darken-4"
              dark
              >assign</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <edit-employee-modal
        v-if="edit_employee"
        :item="edit_employee_item"
        @close="edit_employee = false"
      />
      <div v-if="show_alert">
        <confirmation-dialog
          icon="mdi-alert-outline"
          class="alert"
          type="warning"
          :message="this.message"
          @confirm-event="submit"
          @cancel-event="close_confirmation"
        />
      </div>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import AddEmployeeModal from "../components/Employee/addEmployeeModal.vue";
import AttendanceModal from "../components/Employee/attendanceModal.vue";

export default {
  components: {
    ConfirmationDialog: () => import("@/components/ConfirmationDialog.vue"),
    EditEmployeeModal: () =>
      import("@/components/Employee/editEmployeeModal.vue"),

    AddEmployeeModal,
    AttendanceModal,
  },
  data() {
    return {
      search: "",
      edit_employee: false,
      edit_employee_item: null,
      show_alert: false,
      message: "",
      selected: [],
      selected_schedules: [],
      table_loader: false,
      schedule_form: {
        schedules: [],
        employees: [],
      },
      schedule_dialog: false,
      headers: [
        { text: "Action", value: "actions", filterable: false },
        { text: "User ID", value: "userid", filterable: false },
        { text: "Name", value: "name" },
        { text: "Department", value: "department.department_name" },
        { text: "Employment Status", value: "employment_status" },
        { text: "Imported by", value: "user.username" },
        { text: "Imported from", value: "device.device_name" },
        { text: "Import Date", value: "created_at" },
        { text: "Updated Date", value: "updated_at" },
        // {text: "id", value: "id"},
      ],
    };
  },
  computed: {
    ...mapGetters({
      employees: "employee/employees",
      departments: "department/departments",
      schedules: "schedule/schedules",
      user: "auth/user",
    }),
  },
  methods: {
    del(item) {
      let x = window.confirm("Are you sure you want to delete this?");
      if (x) {
        this.delete_employee(item.id)
          .then(() => {
            alert("Successfully deleted an account");
          })
          .catch(() => {
            alert("Something went wrong");
          });
      }
    },
    edit(item) {
      this.edit_employee_item = item;
      this.edit_employee = true;
      // console.log(this.edit_employee_item);
    },

    ...mapActions({
      get_employees: "employee/get_employees",
      get_attendances: "employee/get_attendances",
      get_departments: "department/get_departments",
      set_notification: "notification/set_notification",
      delete_employee: "employee/delete_employee",
    }),
    add_employee_schedule() {
      if (this.schedule_form.employees.length == 0) {
        this.set_notification({
          message: "No selected employees",
          color: "red",
          showing: true,
          icon: "mdi-close",
        });
        return;
      } else {
        this.schedule_dialog = true;
      }
    },

    confirm_submit() {
      console.log(this.schedule_form);
      this.message = "Please click confirm to proceed";
      this.show_alert = true;
    },
    close_confirmation() {
      this.show_alert = false;
    },
    async submit() {
      this.show_alert = false;

      await this.update_employee_schedule(this.schedule_form);

      this.set_notification({
        message: "Successfully updated employee schedule",
        color: "success",
        showing: true,
        icon: "mdi-check",
      });
    },
  },
  async created() {
    this.table_loader = true;
    if (this.employees.length <= 0) await this.get_employees();
    if (this.departments.length <= 0) await this.get_departments(this.user.id);

    this.table_loader = false;
  },
};
</script>

<style></style>
