<template>
  <div>
    <v-btn small color="yellow darken-1" @click="dialog = true" icon
      ><v-icon>mdi-pencil</v-icon></v-btn
    >
    <v-dialog v-model="dialog" width="450" class="ma-auto" persistent>
      <v-card>
        <v-card-title>Edit Department</v-card-title>
        <v-card-text>
          <v-text-field
            label="Department Name"
            v-model="item.department_name"
          ></v-text-field>
          <v-text-field
            label="Signatory"
            v-model="item.signatory"
          ></v-text-field>
          <v-text-field
            label="Signatory Position"
            v-model="item.signatory_position"
          ></v-text-field>
          <p class="mb-n4">Select Morning Out</p>
          <div class="d-flex">
            <v-select label="Hour" :items="hour" v-model="item.hour"></v-select>
            <v-select
              class="ml-4"
              label="Minutes"
              :items="minutes"
              v-model="item.minutes"
            ></v-select>
          </div>
        </v-card-text>

        <v-card-actions
          ><v-btn @click="edit_dep">Edit</v-btn>
          <v-btn @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["item"],
  created() {
    let x;
    for (x = 1; x <= 60; x++) {
      this.minutes.push(x);
      if (x <= 24) {
        this.hour.push(x);
      }
    }
  },
  data() {
    return {
      show_alert: false,
      alert_message: "",
      alert_type: "",
      dialog: false,
      form: {},
      hour: [],
      minutes: [],
    };
  },
  methods: {
    ...mapActions({
      edit_department: "department/edit_department",
    }),

    async edit_dep() {
      // combine hour and date in morning out
      this.item.morning_out = this.item.hour + ":" + this.item.minutes;
      await this.edit_department(this.item);
      console.log(this.form);
      alert("Successfully edited a new department");
    },
  },
};
</script>

<style lang="scss" scoped></style>
