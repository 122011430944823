<template>
  <v-snackbar v-model="snackbar" :color="color" right shaped top
    ><span class="overline">{{ text }}</span></v-snackbar
  >
</template>

<script>
export default {
  data() {
    return {
      snackbar: true,
      colorMap: {
        error: "red",
        success: "green",
        info: "blue",
      },
    };
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "info",
      validator: (value) => {
        return ["error", "success", "info"].includes(value);
      },
    },
    timeout: {
      type: Number,
      default: 6000,
    },
  },
  computed: {
    color() {
      return this.colorMap[this.type];
    },
  },
};
</script>
