<template>
  <sign-in />
</template>

<script>
import SignIn from "../components/SignIn";

export default {
  name: "HomeView",

  components: {
    SignIn,
  },
};
</script>
