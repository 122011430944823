import axios from "axios";
export default {
  namespaced: true,
  state: {
    // state of app. can store anything from strings to objects
    employees: [],
    employee_attendances: [],
    is_connected: false,
  },
  // read info from state
  getters: {
    employees(state) {
      return state.employees;
    },
    employee_attendances(state) {
      return state.employee_attendances;
    },
    is_connected(state) {
      return state.is_connected;
    },
  },
  mutations: {
    ADD_EMPLOYEE(state, employee) {
      state.employees.unshift(employee);
    },
    SET_EMPLOYEES(state, employees) {
      state.employees = employees;
    },
    SET_EMPLOYEE_ATTENDANCES(state, employees) {
      state.employee_attendances = employees;
      /* create a new multi dimensional array 
      with: 
      Date, 
      AM IN, AM OUT, 
      PM IN, PM OUT,
      OVERTIME IN, OVERTIME OUT
      REGULAR HOURS
      OVERTIME HOURS
      */
    },
    EDIT_EMPLOYEE(state, employee) {
      let index = null;
      state.employees.forEach(function (element, i) {
        if (element.id == employee.id) index = i;
      });
      state.employees.splice(index, 1, employee);
    },
    SET_CONNECTION(state, status) {
      state.is_connected = status;
    },
    DELETE_EMPLOYEE(state, employee_id) {
      let filtered = state.employees.filter((c) => {
        return c.id != employee_id;
      });
      state.employees = filtered;
    },
  },
  actions: {
    add_employee({ commit }, request) {
      let response = axios
        .post("/employee", request)
        .then(() => {
          alert("Successfully added a new user");
          commit("ADD_EMPLOYEE", response.data);
        })
        .catch((err) => {
          alert("Error:" + err.response.data.error);
        });
    },
    async delete_employee({ commit }, employee_id) {
      await axios.delete("/employee/" + employee_id);
      commit("DELETE_EMPLOYEE", employee_id);
    },

    async get_employees({ commit }) {
      let response = await axios.get("/employees");
      commit("SET_EMPLOYEES", response.data);
    },
    async get_attendances({ commit }, request) {
      let response = await axios.post("/get_attendances", request);
      commit("SET_EMPLOYEE_ATTENDANCES", response.data);
    },

    async update_employee({ commit }, request) {
      let response = await axios.put("/employee", request);
      commit("EDIT_EMPLOYEE", response.data);
    },
  },
};
