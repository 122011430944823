<template>
  <div>
    <v-container class="pa-10">
      <div class="mb-5">
        <div class="d-flex">
          <h2>Account Information</h2>
          <v-spacer></v-spacer>
        </div>
        <v-divider class="mt-2 mb-2"></v-divider>
        <v-row>
          <v-col>
            <p>Username: {{ user.username }}</p>
            <p>Device Limit: {{ user.device_limit }}</p>
          </v-col>
          <v-col>
            <p>Date Created: {{ user.created_at }}</p>
            <p>Access Level: {{ user.access_level }}</p></v-col
          >
        </v-row>
      </div>

      <div class="d-flex">
        <h2>Device Information</h2>
        <v-spacer></v-spacer>
        <v-btn color="primary" small @click="add_device = true"
          >Add Device</v-btn
        >
      </div>
      <v-divider class="mb-2 mt-2"></v-divider>
      <p class="overline">Registered Devices</p>
      <v-data-table :items="devices" :headers="device_headers">
        <template v-slot:[`item.actions`]="{ actions }">
          <v-btn icon color="yellow darken-2"
            ><v-icon>mdi-pencil</v-icon></v-btn
          >
          <v-btn icon color="red" class="ml-2"
            ><v-icon>mdi-delete</v-icon></v-btn
          >
        </template>
      </v-data-table>
    </v-container>
    <add-device-modal @close="add_device = false" v-if="add_device" />
    <add-user-modal @close="add_user = false" v-if="add_user" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  created() {
    if (this.devices.length <= 0) this.get_devices();
  },
  components: {
    "add-device-modal": () => import("@/components/Device/addDeviceModal.vue"),
    "add-user-modal": () => import("@/components/User/addUserModal.vue"),
  },
  computed: {
    ...mapGetters({
      devices: "device/devices",
      user: "auth/user",
    }),
  },
  data() {
    return {
      button_loading: false,
      show_alert: false,
      alert_message: "",
      alert_type: "",
      authentication: "",
      authentication_code: "jmbcomputers2023",
      authentication_dialog: true,
      authenticated: false,
      add_device: false,
      add_user: false,
      device_headers: [
        {
          text: "Device ID",
          value: "id",
        },
        { text: "Device Name", value: "device_name" },
        { text: "Username", value: "user.username" },
        {
          text: "Serial Number",
          value: "serial_number",
        },
        {
          text: "Device IP Address",
          value: "ip_address",
        },
        {
          text: "Created at",
          value: "created_at",
        },
        {
          text: "Updated at",
          value: "updated_at",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      get_devices: "device/get_devices",
      signOut: "auth/signout",
    }),
    show_alert_bar(message, type) {
      this.show_alert = true;
      this.alert_message = message;
      this.alert_type = type;
      setTimeout(() => {
        this.show_alert = false;
      }, 6000);
    },
    verify() {
      if (this.authentication == this.authentication_code) {
        this.get_devices().then(() => {
          this.authenticated = true;
          this.authentication_dialog = false;
          this.show_alert_bar("Authenticated", "success");
        });
      } else {
        this.show_alert_bar("Access Denied", "error");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
