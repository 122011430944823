import axios from "axios";
export default {
  namespaced: true,
  state: {
    // state of app. can store anything from strings to objects
    devices: [],
  },
  // read info from state
  getters: {
    devices(state) {
      return state.devices;
    },
  },
  mutations: {
    // update the state
    SET_DEVICES(state, devices) {
      state.devices = devices;
    },
    EDIT_DEVICE(state, device) {
      let index = null;
      state.devices.forEach(function (element, i) {
        if (element.id == device.id) index = i;
      });
      state.devices.splice(index, 1, device);
    },
    ADD_DEVICE(state, device) {
      state.devices.unshift(device);
    },
  },
  actions: {
    async get_devices({ commit }) {
      let response = await axios.get("/devices");
      commit("SET_DEVICES", response.data);
    },
    async add_device({ commit }, request) {
      let response = await axios.post("/device", request);
      commit("ADD_DEVICE", response.data);
    },
    async edit_device({ commit }, request) {
      let response = await axios.put("/device", request);
      commit("EDIT_DEVICE", response.data);
    },
  },
};
