<template>
  <div>
    <v-btn color="primary" small @click="dialog = true"
      >Add New Department</v-btn
    >
    <v-dialog v-model="dialog" width="450" class="ma-auto" persistent>
      <v-card>
        <v-card-title>Add New Department</v-card-title>
        <v-card-text>
          <v-text-field
            label="Department Name"
            v-model="form.department_name"
          ></v-text-field>
          <v-text-field
            label="Signatory"
            v-model="form.signatory"
          ></v-text-field>
          <v-text-field
            label="Signatory Position"
            v-model="form.signatory_position"
          ></v-text-field>
          <p class="mb-n4">Select Morning Out</p>
          <div class="d-flex">
            <v-select label="Hour" :items="hour" v-model="form.hour"></v-select>
            <v-select
              class="ml-4"
              label="Minutes"
              :items="minutes"
              v-model="form.minutes"
            ></v-select>
          </div>
        </v-card-text>

        <v-card-actions
          ><v-btn @click="add_dep">Add</v-btn>
          <v-btn @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  created() {
    let x;
    for (x = 1; x <= 60; x++) {
      this.minutes.push(x);
      if (x <= 24) {
        this.hour.push(x);
      }
    }
  },
  data() {
    return {
      show_alert: false,
      alert_message: "",
      alert_type: "",
      dialog: false,
      form: {},
      hour: [],
      minutes: [],
    };
  },
  methods: {
    ...mapActions({
      add_department: "department/add_department",
    }),

    async add_dep() {
      // combine hour and date in morning out
      this.form.morning_out = this.form.hour + ":" + this.form.minutes;
      await this.add_department(this.form);
      console.log(this.form);
      alert("Successfully added a new department");
    },
  },
};
</script>

<style lang="scss" scoped></style>
