<template>
  <div>
    <v-btn color="primary" icon @click="dialog = true"
      ><v-icon>mdi-calendar-account</v-icon></v-btn
    >
    <v-dialog v-model="dialog" width="950" class="ma-auto">
      <v-card class="pa-2">
        <v-card-title>
          <h2 class="display-1">{{ item.name }}</h2>
          <v-spacer></v-spacer>
          <div class="mt-2 overline">
            <p>Department: {{ item.department.department_name }}</p>
            <p class="mt-n5 overline">Status: {{ item.employment_status }}</p>
          </div>
        </v-card-title>
        <v-card-text>
          <div class="d-flex">
            <v-select
              label="Month"
              :items="months"
              item-text="text"
              item-value="value"
              v-model="request_form.month"
              class="mr-5"
            ></v-select>
            <v-select
              label="Year"
              :items="[2024, 2025, 2026, 2027, 2028]"
              v-model="request_form.year"
            ></v-select>
            <v-btn
              class="mt-3 ml-2"
              small
              color="primary"
              @click="search_attendance"
              >Search</v-btn
            >
            <v-btn
              color="success"
              class="mt-1 ml-2"
              @click="add_attendance_dialog = true"
              ><v-icon>mdi-plus</v-icon></v-btn
            >
            <add_attendance_modal
              :item="item"
              v-if="add_attendance_dialog"
              @close="add_attendance_dialog = false"
            />
          </div>
          <!-- <v-divider></v-divider> -->
          <v-text-field v-model="search" label="Search"></v-text-field>
          <v-data-table
            :search="search"
            :items="attendances"
            :headers="headers"
          >
            <template v-slot:[`item.action`]="{ item }">
              <v-btn icon color="orange" @click="open_edit_attendance(item)"
                ><v-icon>mdi-pencil</v-icon></v-btn
              >

              <v-btn icon color="red" @click="del(item)"
                ><v-icon>mdi-delete</v-icon></v-btn
              >
              <!-- <v-chip v-if="item.type == 0" color="primary">Log in</v-chip>
              <v-chip v-else color="error">Log out</v-chip> -->
            </template>

            <template v-slot:[`item.type`]="{ item }">
              <v-chip v-if="item.type == 0" color="primary">Log in</v-chip>
              <v-chip v-else color="error">Log out</v-chip>
            </template>

            <template v-slot:[`item.state`]="{ item }">
              <v-chip v-if="item.state == 1" color="">Fingerprint</v-chip>
              <v-chip v-else color="">Face Recognition</v-chip>
            </template>

            <template v-slot:[`item.is_morning`]="{ item }">
              <v-chip v-if="item.is_morning == 1" color="">Yes</v-chip>
              <v-chip v-else color="">No</v-chip>
            </template>
          </v-data-table>
          <edit-attendance-modal
            :item="selected_attendance"
            v-if="edit_attendance_dialog"
            @close="edit_attendance_dialog = false"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn @click="dialog = false"> Close </v-btn>
          <v-spacer></v-spacer>
          <p class="grey--text caption">attendanceModal.vue</p>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
import { defineAsyncComponent } from "vue";

export default {
  components: {
    add_attendance_modal: defineAsyncComponent(() =>
      import("../Attendance/addAttendanceModal.vue")
    ),
    editAttendanceModal: defineAsyncComponent(() =>
      import("../Attendance/editAttendanceModal.vue")
    ),
  },

  props: ["item"],
  data() {
    return {
      edit_attendance_dialog: false,
      selected_attendance: [],
      search: "",
      add_attendance_dialog: false,
      attendances: [],
      headers: [
        { text: "Action", value: "action" },
        { text: "ID", value: "id" },
        { text: "Timestamp", value: "timestamp" },
        { text: "Type", value: "type" }, //0 == log in 1 == logout
        { text: "Attendance Mode", value: "state" }, //1 == fingerprint 16 == face
        { text: "Is Morning", value: "is_morning" },
      ],
      error_message: null,
      button_loading: false,
      show_alert: false,
      alert_message: "",
      alert_type: "",
      dialog: false,
      months: [
        { text: "January", value: 1 },
        { text: "Febuary", value: 2 },
        { text: "March", value: 3 },
        { text: "April", value: 4 },
        { text: "May", value: 5 },
        { text: "June", value: 6 },
        { text: "July", value: 7 },
        { text: "August", value: 8 },
        { text: "September", value: 9 },
        { text: "October", value: 10 },
        { text: "November", value: 11 },
        { text: "December", value: 12 },
      ],

      request_form: {
        month: 1,
        year: 2024,
      },
      form: {
        username: null,
        password: null,
      },
    };
  },
  methods: {
    open_edit_attendance(item) {
      this.edit_attendance_dialog = true;
      this.selected_attendance = item;
    },
    del(item) {
      let x = window.confirm("Are you sure you want to proceed?");
      if (x) {
        axios
          .delete("/attendance/" + item.id)
          .then(() => {
            alert("Operation successful. Please refresh data");
          })
          .catch((err) => {
            alert(err);
          });
      }
    },
    search_attendance() {
      // check
      if (this.request_form.month == "" || this.request_form.year == "")
        return alert("Input comlete date first");

      axios
        .get(
          "/get_individual_attendances/" +
            this.item.userid +
            "/" +
            this.request_form.month +
            "/" +
            this.request_form.year
        )
        .then((response) => {
          this.attendances = response.data;
          alert("Operation Successful");
        })
        .catch((err) => {
          return alert(err);
        });
    },
    check_password() {
      if (this.form.passwod !== this.form.confirm_password) {
        this.error_message = "Password do not match";
      } else {
        this.error_message = null;
      }
    },
    ...mapActions({
      add_user: "user/add_user",
    }),
    async submit_user() {
      if (!this.form.username || !this.form.password) {
        this.show_alert_bar("Please check all fields!", "error");
        return;
      } else {
        this.button_loading = true;
        await this.add_user(this.form)
          .then(() => {
            alert("Successfully added a new user");
          })
          .catch((e) => {
            alert(e);
          });

        this.button_loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
