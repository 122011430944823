<template>
  <div>
    <v-btn small color="primary" @click="dialog = true">Add Employee</v-btn>
    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <v-card-title>Add Employee</v-card-title>
        <v-card-text>
          <v-text-field
            label="Employee ID"
            v-model="form.userid"
          ></v-text-field>
          <v-text-field
            label="Employee Name"
            v-model="form.name"
          ></v-text-field>
          <v-select
            label="Department"
            :items="departments"
            item-text="department_name"
            item-value="id"
            v-model="form.department_id"
          ></v-select>
          <v-select
            label="Employment Status"
            :items="['Regular', 'Contract of Service', 'Job Order']"
            v-model="form.employment_status"
          ></v-select>
          <v-select
            label="Device Name"
            :items="devices"
            item-text="device_name"
            item-value="id"
            v-model="form.device_id"
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="add" color="primary">Add</v-btn>
          <v-btn @click="dialog = false" color="">Close</v-btn>
        </v-card-actions>
      </v-card></v-dialog
    >
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      form: {},
    };
  },
  methods: {
    add() {
      this.loading = true;
      let x = window.confirm("Are you sure you want to add this employee?");
      if (x) {
        this.add_employee(this.form);
      }
      this.loading = false;
    },
    ...mapActions({
      get_departments: "department/get_departments",
      add_employee: "employee/add_employee",
      get_devices: "device/get_devices",
    }),
  },
  created() {
    if (this.departments.length <= 0) this.get_departments();
    if (this.devices.length <= 0) this.get_devices();
  },
  computed: {
    ...mapGetters({
      departments: "department/departments",
      devices: "device/devices",
    }),
  },
};
</script>

<style></style>
