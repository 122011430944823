<template>
  <v-container>
    <div class="mt-10">
      <div class="d-flex mb-10">
        <h2>Schedule Management</h2>
        <v-spacer></v-spacer>
        <v-btn color="primary" small outlined @click="department_modal = true"
          >Add New Department</v-btn
        >
      </div>

      <add-schedule :dialog="dialog" @close="dialog = !dialog" />
      <edit-schedule
        :dialog="edit_dialog"
        :item="edit_item"
        @close="edit_dialog = !edit_dialog"
      />
      <v-text-field
        label="Search"
        v-model="search"
        prepend-icon="mdi-magnify"
        :table_loader="table_loader"
      ></v-text-field>
      <v-data-table :items="schedules" :headers="headers"></v-data-table>
    </div>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    AddSchedule: () =>
      import("@/components/ScheduleManagement/AddSchedule.vue"),
    EditSchedule: () =>
      import("@/components/ScheduleManagement/EditSchedule.vue"),
  },
  data() {
    return {
      search: "",
      dialog: false,
      edit_item: null,
      delete_id: null,
      edit_dialog: false,
      table_loader: false,
      headers: [
        { text: "ID", value: "id" },
        { text: "Description", value: "description" },
        { text: "First In", value: "first_in" },
        { text: "First Out", value: "first_out" },
        { text: "Second In", value: "second_in" },
        { text: "Second Out", value: "second_out" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      schedules: "schedule/schedules",
    }),
  },
  methods: {
    ...mapActions({
      get_schedules: "schedule/get_schedules",
      set_notification: "notification/set_notification",
    }),
    open_edit_dialog(item) {
      this.edit_item = item;
      this.edit_dialog = true;
    },
  },
  async created() {
    if (!this.schedules) {
      try {
        this.set_progress(true);
        await this.get_schedules();
        this.set_progress(false);
      } catch (error) {
        this.set_notification({
          message: "Something went wrong! Error:" + error,
          color: "red",
          showing: true,
          icon: "mdi-close",
        });
      }
    }
  },
};
</script>
<style></style>
