<template>
  <div>
    <v-btn color="primary" @click="dialog = true">Print Attendance</v-btn>
    <v-dialog v-model="dialog" width="450" class="ma-auto" persistent>
      <v-card>
        <v-card-title>Generate Attendance</v-card-title>
        <v-card-text>
          <v-select
            label="Device Name"
            :items="devices"
            item-text="device_name"
            item-value="id"
            v-model="form.device_id"
          ></v-select>
          <v-select
            prepend-icon="mdi-domain"
            :items="departments"
            label="Departments"
            item-text="department_name"
            item-value="id"
            v-model="form.department_id"
          ></v-select>
          <v-select
            prepend-icon="mdi-calendar"
            :items="dates"
            label="Month"
            item-text="text"
            item-value="value"
            v-model="form.month"
          ></v-select>
          <v-select
            :items="year"
            label="Year"
            v-model="form.year"
            prepend-icon="mdi-calendar"
          ></v-select>
          <v-select
            prepend-icon="mdi-account"
            label="Employment Status"
            :items="['Regular', 'Contract of Service', 'Job Order']"
            v-model="form.employment_status"
            @change="check_employment_status"
          ></v-select>
          <div v-if="enable_date_from_to" class="d-flex">
            <v-select
              label="From"
              class="mr-2"
              :items="days"
              v-model="form.date_from"
            ></v-select>
            <v-select
              label="To"
              :items="days"
              v-model="form.date_to"
            ></v-select>
          </div>
        </v-card-text>
        <v-card-actions>
          <div>
            <v-btn
              small
              color="primary"
              class="ml-3"
              @click="print_attendance('detailed')"
              >Print Detailed</v-btn
            >
            <v-btn
              small
              class="ml-3 white--text"
              color="green"
              @click="print_attendance('cs')"
              >Print CS FORM</v-btn
            >
            <v-btn small class="mr-2 ml-2" @click="dialog = false"
              >Cancel</v-btn
            >
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      enable_date_from_to: false,
      form: {
        device_id: "",
        department_id: "",
        month: "",
        year: "",
        employment_status: "",
        date_from: "",
        date_to: "",
      },
      days: [],
      dialog: false,
      dates: [
        { text: "January", value: 1 },
        { text: "Febuary", value: 2 },
        { text: "March", value: 3 },
        { text: "April", value: 4 },
        { text: "May", value: 5 },
        { text: "June", value: 6 },
        { text: "July", value: 7 },
        { text: "August", value: 8 },
        { text: "September", value: 9 },
        { text: "October", value: 10 },
        { text: "November", value: 11 },
        { text: "December", value: 12 },
      ],
      year: [2023, 2024, 2025, 2026, 2027],
    };
  },
  computed: {
    ...mapGetters({
      devices: "device/devices",
      departments: "department/departments",
      user: "auth/user",
      default_server_url: "auth/default_url",
    }),
  },
  methods: {
    ...mapActions({
      get_departments: "department/get_departments",
    }),
    check_employment_status() {
      this.enable_date_from_to = this.form.employment_status === "Job Order";
      if (!this.enable_date_from_to) {
        this.form.date_from = 1;
        this.form.date_to = 31;
      }
    },
    print_attendance(form) {
      // check if any of this is empty
      window.open(
        this.default_server_url +
          "/print_attendance/" +
          this.form.department_id +
          "/" +
          this.form.month +
          "/" +
          this.form.year +
          "/" +
          form +
          "/" +
          this.form.date_from +
          "/" +
          this.form.date_to +
          "/" +
          this.form.employment_status +
          "/" +
          this.user.id
      );
    },
  },
  created() {
    // generate days
    let x = 1;
    for (x; x < 32; x++) {
      this.days.push(x);
    }

    if (this.departments.length <= 0) this.get_departments(this.user.id);
  },
};
</script>

<style></style>
