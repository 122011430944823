import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import AttendanceView from "../views/AttendanceView.vue";
import EmployeeView from "../views/EmployeeView.vue";
import ScheduleView from "../views/ScheduleView.vue";
import DepartmentsView from "../views/DepartmentsView.vue";
import DeviceView from "../views/DeviceView.vue";
import AdminControl from "../views/AdminControl.vue";

import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: HomeView,
    beforeEnter: (to, from, next) => {
      if (store.getters["auth/authenticated"]) {
        next({ name: "attendances" });
        document.title = "JMB BIOMETRICS - " + to.title;
      } else {
        next();
        document.title = "JMB BIOMETRICS - Log in";
      }
    },
  },
  {
    path: "/attendances",
    name: "attendances",
    component: AttendanceView,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        next({ name: "Login" });
        document.title = "JMB BIOMETRICS - " + to.name;
      } else {
        next();
        document.title = "JMB BIOMETRICS - " + to.name;
      }
    },
  },
  {
    path: "/employees",
    name: "employees",
    component: EmployeeView,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        next({ name: "Login" });
        document.title = "JMB BIOMETRICS - " + to.name;
      } else {
        next();
        document.title = "JMB BIOMETRICS - " + to.name;
      }
    },
  },
  {
    path: "/schedules",
    name: "schedules",
    component: ScheduleView,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        next({ name: "Login" });
        document.title = "JMB BIOMETRICS - " + to.name;
      } else {
        next();
        document.title = "JMB BIOMETRICS - " + to.name;
      }
    },
  },
  {
    path: "/departments",
    name: "departments",
    component: DepartmentsView,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        next({ name: "Login" });
        document.title = "JMB BIOMETRICS - " + to.name;
      } else {
        next();
        document.title = "JMB BIOMETRICS - " + to.name;
      }
    },
  },
  {
    path: "/device_info",
    name: "Device Info",
    component: DeviceView,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        next({ name: "Login" });
        document.title = "JMB BIOMETRICS - " + to.name;
      } else {
        next();
        document.title = "JMB BIOMETRICS - " + to.name;
      }
    },
  },
  {
    path: "/admin_controls",
    name: "Admin Control",
    component: AdminControl,
    beforeEnter: (to, from, next) => {
      if (
        !store.getters["auth/authenticated"] ||
        store.getters["auth/user"].access_level < 2
      ) {
        alert("Unauthenticated Access");
        return;
      } else {
        next();
        document.title = "JMB BIOMETRICS - " + to.name;
      }
    },
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
