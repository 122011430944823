import axios from "axios";
export default {
  namespaced: true,
  state: {
    // state of app. can store anything from strings to objects
    departments: [],
  },
  // read info from state
  getters: {
    departments(state) {
      return state.departments;
    },
  },
  mutations: {
    // update the state
    SET_DEPARTMENTS(state, departments) {
      state.departments = departments;
    },
    EDIT_DEPARTMENT(state, employee) {
      let index = null;
      state.departments.forEach(function (element, i) {
        if (element.id == employee.id) index = i;
      });
      state.departments.splice(index, 1, employee);
    },
    ADD_DEPARTMENT(state, department) {
      state.departments.unshift(department);
    },
    DELETE_DEPARTMENT(state, department_id) {
      let filtered = state.departments.filter((c) => {
        return c.id != department_id;
      });
      state.departments = filtered;
    },
  },
  actions: {
    async get_departments({ commit }, user_id) {
      let response = await axios.get("/departments/" + user_id);
      commit("SET_DEPARTMENTS", response.data);
    },
    async edit_department({ commit }, request) {
      let response = await axios.put("department", request);
      commit("EDIT_DEPARTMENT", response.data);
    },
    async add_department({ commit }, request) {
      let response = await axios.post("/department", request);
      commit("ADD_DEPARTMENT", response.data);
    },
    async delete_department({ commit }, department_id) {
      await axios.delete("department/" + department_id);
      commit("DELETE_DEPARTMENT", department_id);
    },
  },
};
