<template>
  <v-layout row justify-center>
    <v-dialog
      v-model="progress_circular"
      persistent
      fullscreen
      scrollable
      hide-overlay
      overlay-opacity="50"
      dark
      transition="fade-transition"
    >
      <v-container fill-height>
        <v-layout row justify-center align-center>
          <v-progress-circular
            class="transparent"
            indeterminate
            :size="100"
            :width="15"
            :value="90"
            :rotate="360"
            color="grey"
          ></v-progress-circular>
        </v-layout>
      </v-container>
    </v-dialog>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      progress_circular: "auth/progress_circular",
    }),
  },
};
</script>

<style></style>
