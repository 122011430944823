<template>
  <v-app>
    <v-main>
      <the-nav-bar />
      <the-app-bar />
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import TheAppBar from "./components/TheAppBar.vue";
import TheNavBar from "./components/TheNavBar.vue";

export default {
  name: "App",
  components: {
    TheNavBar,
    TheAppBar,
  },
  data: () => ({
    device_ip_address: "",
  }),
  computed: {
    ...mapGetters({
      progress_circular: "auth/progress_circular",
      devices: "device/devices",
    }),
  },

  methods: {
    ...mapActions({
      insert_am_or_pm_to_attendance_table:
        "attendance/insert_am_or_pm_to_attendance_table",
      get_devices: "device/get_devices",
    }),
    ...mapMutations({
      set_progress: "auth/SET_PROGRESS_CIRCULAR",
    }),
  },
  created() {
    // Use a watcher on $route
    this.$watch(
      () => this.$route,
      (to, from) => {
        document.title = "JMB BIOMETRICS - " + to.name;
      }
    );
  },
};
</script>
<style>
html {
  overflow-y: auto;
}
</style>
