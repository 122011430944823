<template>
  <v-app-bar app color="" v-if="authenticated">
    <v-toolbar-title>
      <v-img :src="require('@/assets/jmb_computers.png')" width="200" />
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-toolbar-items class="hidden-sm-and-down">
      <div v-if="authenticated" class="mt-3 d-flex">
        <h3 class="font-weight-bold">{{ this.user.username }}</h3>
        <v-btn small @click="logOut" text>
          <v-icon left dark>mdi-open-in-new</v-icon>
          Logout
        </v-btn>
      </div>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      device_ip_address: "",
      attendance_loading: false,
      employee_loading: false,
    };
  },
  created() {
    // check if exists

    if (this.devices.length <= 0) {
      this.get_devices();
    }
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      devices: "device/devices",
      user: "auth/user",
    }),
  },
  methods: {
    async logOut() {
      await this.signOut();

      this.$router.push("/");
    },
    async import_employees() {
      this.employee_loading = true;
      if (this.device_ip_address == "") {
        alert("Please select device first");
        this.employee_loading = false;
        return;
      }

      await this.download_users(this.device_ip_address).then(() => {
        alert("Successfully downloaded employees");
      });

      this.employee_loading = false;
    },

    ...mapActions({
      signOut: "auth/signout",
      download_users: "employee/download_users",
      download_attendances: "attendance/download_attendances",
      get_devices: "device/get_devices",
    }),
  },
};
</script>

<style></style>
